import { Button, ButtonGroup, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { PersonRESTView } from "../../components/model/Person";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { useGetPersonQuery } from "../../features/model/personSlice";
import Loading from "../../components/Loading";
import { ResumeCard } from "../ResumesView";
import { JobCard } from "../JobsView";

const AccountsAdminView = () => {
    return <Stack direction="column" sx={{mt: 4, alignSelf: "center"}} spacing={4}>
            <PersonRESTView extra={{sort: "created"}} />
        </Stack>;
}

const AccountResumes = ({resumes}) => {
    return <Stack direction="column" spacing={1}>
        {resumes.map((r) => {
            return <ResumeCard resume={r} key={r.id} />
        })}
    </Stack>;
}

const AccountJobs = ({jobs}) => {
    return <Stack direction="column" spacing={1}>
        {jobs.map((j) => {
            return <JobCard job={j} key={j.id} />
        })}
    </Stack>;
}


const AccountAdminView = () => {
    const params = useParams();
    const navigate = useNavigate();
    const user = useGetPersonQuery(params.personId);
    if(!user.data || user.isLoading || user.isError) return <Loading />;
    const data = user.data;

    return <Stack direction="column" spacing={2} sx={{mt:4}}>
        <ButtonGroup><Button onClick={() => {navigate(-1);}}>Back</Button></ButtonGroup>
        <Typography><b>User:</b> {data.name}</Typography>
        <Typography><b>Email:</b> {data.email}</Typography>
        <Typography><b>Created:</b> {data.created}</Typography>
        <Card>
            <CardHeader title={"Resumés"} />
            <CardContent>
                <AccountResumes resumes={data.resumes} />
            </CardContent>
        </Card>
        <Card>
            <CardHeader title={"Job descriptions"} />
            <CardContent>
                <AccountJobs jobs={data.jobs} />
            </CardContent>
        </Card>
    </Stack>;
}

export { AccountsAdminView, AccountAdminView };
