import { Box, Button, capitalize, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useGetStatsByKindQuery } from "../../features/adminSlice";
import Loading from "../../components/Loading";

function formatKey(key) {
    let parts = key.split("_");
    let ret = "";
    parts.forEach((part) => {
        ret = ret + capitalize(part) + " ";
    })
    return ret.trimEnd();
}

function formatValue(value) {
    let fval = parseFloat(value);
    let ival = parseInt(value);
    if(!fval) {
        return value;
    }
    if(fval === ival) {
        return `${ival}`
    }
    return `${fval.toFixed(2)}`;
}

const StatsView = ({stats}) => {
    return Object.keys(stats).sort().map((group) => {
        let groupStats = stats[group];
        return <Stack key={group} direction="column" spacing={1}>
            <Typography variant="h2">{formatKey(group)}</Typography>
            {Object.keys(groupStats).sort().map((statKey) => {
                let stat = groupStats[statKey];
                return <Box key={statKey} sx={{paddingLeft: 2}}><Typography variant="body1">
                    {formatKey(statKey)}: <b>{formatValue(stat)}</b>
                </Typography></Box>;
            })}
        </Stack>;
    });
}

const Admin = () => {
    const statsQuery = useGetStatsByKindQuery("admin");

    return <Stack direction="column" sx={{mt: 4, alignSelf: "center"}} spacing={4}>
        <Card>
            <CardHeader title="Administration" />
            <CardContent>
                <Stack direction="column" spacing={2}>
                    <Button component={Link} to="/admin/feed">Feed</Button>
                    <Button component={Link} to="/admin/company">Companies</Button>
                    <Button component={Link} to="/admin/accounts">Accounts</Button>
                    <Button component={Link} to="/admin/test">Test document upload</Button>
                </Stack>
            </CardContent>
        </Card>
        <Card>
            <CardHeader title="Current Stats" />
            <CardContent>
                <Stack direction="column" spacing={2}>
                    {statsQuery.isLoading || !statsQuery.data ? <Loading /> : <StatsView key={"stats"} stats={statsQuery.data} />}
                </Stack>
            </CardContent>
        </Card>
    </Stack>;
}

export { Admin };