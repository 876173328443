import { Autocomplete, Box, Button, Card, CardActions, CardContent, CardHeader, FormControl, FormLabel, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, ToggleButton, Typography } from "@mui/material";

import Loading from "../Loading";
import { useState } from "react";

import MDEditor from '@uiw/react-md-editor';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import { Link } from "react-router-dom";

dayjs.extend(utc);

function capitalized(text) {
    if(text.length < 3) {
        return text.toUpperCase();
    }
    return text.charAt(0).toUpperCase() + text.slice(1).replace("_", " ");
}

function arbitrarySort(a, b, field) {
    console.log(a,b,field.type);
    if(field.type === "str") {
        return a.localeCompare(b);
    } else if(field.type === "float" || field.type === "int") {
        if(a<b) return -1;
        if(a>b) return 1;
        return 0;
    } else if(field.type === "datetime") {
        let da = dayjs(a);
        let db = dayjs(b);
        return da.diff(db);
    }
    return 0;
}

const RESTMarkDownEditor = ({field, text, onChange, readOnly=false}) => {
    const [value, setValue] = useState(text);
    function propagateChange(text) {
        if(readOnly) {
            return;
        }
        setValue(text);
        onChange(text);
    }
    return <div data-color-mode="light">
        <div className="wmde-markdown-var"> </div>
        <Stack direction="column" sx={{marginLeft: "1px", marginRight: "1px"}}>
        <FormLabel>{field.label || capitalized(field.name) || "(unknown)"}</FormLabel>
        <MDEditor value={value} onChange={propagateChange} />
        </Stack>
    </div>;
}

function defaultsForSpec(spec) {
    var ret = {};
    spec.forEach(field => {
        if(field.default) {
            ret[field.name] = field.default;
        }
    })
    return ret;
}

function convertData(spec, filters, data) {
    var ret = {...data, ...filters};
    // Convert #now for datetime fields
    spec.forEach((field) => {
        if(data[field.name]) {
            if(field.type === "datetime") {
                if (data[field.name] === "#now") {
                    ret[field.name] = dayjs().utc()
                } else if (data[field.name] !== "") {
                    ret[field.name] = dayjs(ret[field.name]).utc();
                } else {
                    ret[field.name] = null
                }
            }
        }
    });
    return ret;
}

function convertIncomingData(spec, filters, data) {
    var ret = {...data};
    
    // Convert #now for datetime fields
    spec.forEach((field) => {
        if(data[field.name]) {
            if(field.type === "datetime") {
                if (data[field.name] === "#now") {
                    ret[field.name] = dayjs()
                } else if (data[field.name] !== "") {
                    ret[field.name] = dayjs(ret[field.name]);
                } else {
                    ret[field.name] = filters[field.name] ?? null;
                }
            }
        }
    });
    return ret;
}

function formField(field, filters, options, data, setData, extraProps = {}) {
    let defValue = data[field.name] || filters[field.name] || "";
    let label = field.label || capitalized(field.name) || "";
    var theseOptions = [...options[field.name] || []];
    if((field.type || "str") === "str") {
        if (Object.keys(options).includes(field.name)) {
            theseOptions.splice(0,0,"");
            return <Autocomplete sx={{flexGrow: 1}}
                {...extraProps}
                defaultValue={defValue}
                options={theseOptions} onChange={(e) => {
                    setData({...data, [field.name]: e.target.textContent});
                }} 
                freeSolo renderInput={params => <TextField {...extraProps} {...params} onChange={(e) => {
                    setData({...data, [field.name]: e.target.value});
                }} />} />;
        } else if ((field.uitype || "") === "markdown") {
            return <RESTMarkDownEditor field={field} text={defValue} onChange={(text) => {
                setData({...data, [field.name]: text});
            }} />;
        }
        return <TextField {...extraProps} sx={{flexGrow: 1}}
                defaultValue={defValue} label={label} onChange={(e) => {
            setData({...data, [field.name]: e.target.value});
        }} />
    } else if (field.type === "float") {
        return <TextField {...extraProps} sx={{flexGrow: 1}}
            defaultValue={defValue} label={label} onChange={(e) => {
            setData({...data, [field.name]: parseFloat(e.target.value) ?? 0.0});
        }} />
    } else if (field.type === "datetime") {
        return <DateTimePicker {...extraProps} label={label} defaultValue={defValue === "" ? null : defValue === "#now" ? dayjs() : defValue} onChange={(e) => {
            setData({...data, [field.name]: dayjs(e).utc()})
        }} />
    } else if (field.type === "bool") {
        return <Switch {...extraProps} defaultChecked={defValue === "1" || defValue === 1 || defValue === true} onChange={(e) => {
            setData({...data, [field.name]: e.target.checked});
        }} />;
    } else if (field.type === "id") {
        if (Object.keys(options).includes(field.name)) {
            theseOptions.splice(0,0,[null]);
            return <Select {...extraProps} defaultValue={defValue} onChange={(e) => {
                setData({...data, [field.name]: e.target.value});
            }}>
                {theseOptions.map((o) => <MenuItem key={o.id || 0} value={o.id || null} selected={(o.id || null) === defValue}>{o.name || "(none)"}</MenuItem>)}
            </Select>;
        }
    }
    return <></>;
}

const RESTAddEditCard = ({spec, addMutation, updateMutation, filters={}, options={}, update=false, objectType, onClose, currentData=null}) => {

    const [data, setData] = useState(currentData ? convertIncomingData(spec, filters, currentData) : defaultsForSpec(spec));
    const [error, setError] = useState(null);

    function performAdd() {
        addMutation[0](convertData(spec, filters, data));
    }

    function performUpdate() {
        updateMutation[0]({[`${objectType}_id`]: currentData.id, data: convertData(spec, filters, data)});
    }

    let readOnly = false;

    if(addMutation) {
        if(addMutation[1].isLoading) {
            readOnly = true;
        } else if(addMutation[1].isSuccess) {
            onClose();
        } else if (addMutation[1].isError) {
            if (error !== addMutation[1].error.error) {
                setError(addMutation[1].error.error);
            }
        }
    }
    if(updateMutation) {
        if(updateMutation[1].isLoading) {
            readOnly = true;
        } else if(updateMutation[1].isSuccess) {
            onClose();
        } else if (updateMutation[1].isError) {
            if (error !== updateMutation[1].error.error) {
                setError(updateMutation[1].error.error);
            }
        }
    }

    return <Card>
        <CardHeader title={update ? `Update ${objectType}` : `Add new ${objectType}`} />
        <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction="column" gap={1}>
        {
            spec.map((field) => {
                let required = !(field.nullable || false);

                return <FormControl fullWidth key={field.name}>
                <Stack direction="row" textAlign={"end"}>
                <FormLabel variant={"small"} sx={{width: "120px", alignSelf: "center", marginRight: 2}}>{capitalized(field.name)} </FormLabel>
                    {formField(field, filters, options, data, setData, {readOnly: readOnly, required: required})}
                </Stack>
            </FormControl>;})
        }
        </Stack>
        </LocalizationProvider>
        </CardContent>
        <CardActions sx={{justifyContent: "flex-end"}}>
            {error ? <Typography sx={{color: "#f00"}}>{error}</Typography> : <></>}
            <Button variant="outlined" onClick={onClose}>Cancel</Button>
            <Button variant="contained" onClick={update ? performUpdate : performAdd}>{update ? "Update" : "Add"}</Button>
        </CardActions>
    </Card>
}

const RESTList = ({query, spec, filters, extra, objectType, options={}, addMutation, updateMutation, deleteMutation, customAdd=null}) => {
    const [adding, setAdding] = useState(false);
    const [editing, setEditing] = useState(null);

    // "order" is a special float field that allows for in-list sorting
    const sortable = spec.map(s => s.name).includes("order");

    let headerFields = [];
    let headerKeys = [];
    let specByKey = Object.fromEntries(spec.map(field => [field.name, field]));
    spec.filter((s) => s.column || false).forEach(field => {
        headerFields.push(
            <TableCell key={field.name}>{field.label || capitalized(field.name) || ""}</TableCell>
        );
        headerKeys.push(field.name);
    });
    var contentFields = [];

    function renderValue(idx, row_id, field, value) {
        if((field.type || "") === "datetime") {
            return dayjs(value).local().format("D/M/YY HH:mm");
        }
        if((field.type || "") === "id") {
            if(value) {
                return value.name || value.key || value.id;
            } else {
                return "-";
            }
        }
        if((field.type || "") === "bool") {
            if(value) {
                return "X";
            } else {
                return "";
            }
        }
        if(idx === 0) {
            return <Link to={`/${objectType}/${row_id}`}>{value}</Link>;
        }
        return `${value}`;
    }
    var editData = {};
    var use_filters = {...filters};

    if(query.isLoading || !query.data) {
        contentFields = [
            <TableRow key={"loading"}>
                {headerKeys.map((k) => <TableCell key={k}><Loading /></TableCell>)}
                <TableCell key="edit_actions"><Loading /></TableCell>
            </TableRow>
        ];
    } else {
        var rows = query.data.slice();
        if(sortable) {
            rows.sort((a, b) => a.order - b.order);
            const max_order = Math.max(...query.data.map(o => o.order));
            use_filters["order"] = max_order + 10.0;        
        }
        if(extra && extra.sort) {
            rows.sort((a, b) => arbitrarySort(a[extra.sort], b[extra.sort], specByKey[extra.sort]));
            console.log(rows);
        }
        contentFields = rows.map((row, idx) => {
            if(row.id === editing) editData = row;
            return <TableRow key={idx}>{
                headerKeys.map((k, k_idx) => 
                    <TableCell key={k}>{renderValue(k_idx, row.id, specByKey[k], row[k])}</TableCell>
                )}
                <TableCell key="edit_actions">
                    <IconButton key="edit" size="small" onClick={() => {
                        setEditing(row.id);
                    }}><EditIcon /></IconButton>
                    <IconButton key="delete" size="small" onClick={() => {
                        deleteMutation[0](row.id);
                    }}><DeleteIcon /></IconButton>
                </TableCell>
            </TableRow>;
        })
    }
    
    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        { adding ? (
        customAdd ? customAdd({
            spec, filters:use_filters, options, addMutation, objectType, onClose:() => {
                setAdding(false);
                window.location.reload();
            }})
        :
        <RESTAddEditCard
            spec={spec} filters={use_filters} options={options} addMutation={addMutation} objectType={objectType} onClose={() => {
                setAdding(false);
                window.location.reload();
            }}/>
        )
        : (editing !== null) ?
        <RESTAddEditCard
            spec={spec} options={options} updateMutation={updateMutation} objectType={objectType} update={true}
            currentData={editData}
            onClose={() => {
                setEditing(null);
                window.location.reload();
            }}/>
        :
        <Stack direction="column"><TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    {headerFields}
                    <TableCell key="edit_actions">Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {contentFields}
            </TableBody>
            <TableFooter>
            </TableFooter>
        </Table>
        </TableContainer>
        <Stack direction="row" sx={{marginBottom: 3, marginTop: 1}}>
            <Box sx={{flexGrow: 1}}></Box>
            <Button variant="contained" onClick={() => setAdding(true)}>Add new...</Button>
        </Stack>
        </Stack>
        }
        </LocalizationProvider>
}

export {RESTList, convertData, convertIncomingData, formField, defaultsForSpec, capitalized};