import { Stack } from "@mui/material";
import { FeedRESTView } from "../../components/model/Feed";
import { useParams } from "react-router-dom";
import { useGetFeedQuery } from "../../features/model/feedSlice";

const FeedAdminView = () => {
    return <Stack direction="column" sx={{mt: 4, alignSelf: "center"}} spacing={4}>
            <FeedRESTView />
        </Stack>;
}

const FeedAdminByIdView = () => {
    const params = useParams();
    const feedQuery = useGetFeedQuery(params.feedId);
    console.log(feedQuery);
    return <></>;
}

export { FeedAdminView, FeedAdminByIdView };
