import { useUploadResumeMutation } from "../features/uploadSlice";
import { useGetPersonResumesQuery } from "../features/model/personSlice";
import { useDeleteResumeMutation, useUpdateResumeMutation } from "../features/model/resumeSlice";
import { useState } from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Fade, IconButton, Paper, Stack, Typography } from "@mui/material";
import { formatUTCDate } from "../environment/dates";
import Loading from "../components/Loading";
import Uploader from "../components/Uploader";
import Viewer from "../components/Viewer";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";

function ResumeCard({resume}) {
    const [more, setMore] = useState(false);
    const [deleteResume] = useDeleteResumeMutation();
    const [updateResume] = useUpdateResumeMutation();
    const [name, setName] = useState(resume.name ? resume.name : resume.documents[0]?.filename);
    const navigate = useNavigate();
    console.log(resume);

    let uploaded = resume.documents[0]?.created;
    let md = JSON.parse(resume.metadata);
  
    return <Card variant="outlined">
        <CardHeader title={resume.name} action={<IconButton onClick={()=>{setMore(!more);}}><MoreVertIcon /></IconButton>} />
        <CardActions>
            <Button startIcon={<PreviewIcon />} variant="outlined" onClick={() => {
                Viewer(resume.documents[0], true);
            }}>View</Button>
            <Fade mountOnEnter unmountOnExit in={more}>
                <Button color="secondary" startIcon={<DeleteIcon />} variant="outlined" onClick={() => {
                    if (window.confirm(`Are you sure you want to delete the resume ${resume.documents[0].filename}?`)) {
                        deleteResume(resume.id);
                        navigate(0);
                    }
                }}>Delete</Button>
            </Fade>
        </CardActions>
        <CardContent>
            <Fade mountOnEnter unmountOnExit in={more}>
                <Stack direction="column" spacing={1}>
                    <Typography>Uploaded: {formatUTCDate(uploaded)}</Typography>
                    <Typography>Summary:</Typography>
                    <p style={{marginLeft: 16, fontSize: "8pt"}}>{resume.summary}</p>
                    <Typography>Skills:</Typography>
                    <Stack direction="column" spacing={1} sx={{paddingLeft: 2}}>
                    {resume.skills.map((s) => 
                        <Stack direction="row" spacing={2}>
                            <Typography>{s.skill.name}</Typography>
                            <Typography variant="body2">({s.category})</Typography>
                        </Stack>
                    )}
                    </Stack>
                    <Typography>Parsed text:</Typography>
                    <pre style={{fontSize: "8pt"}}>
                        {md.resume_text}
                    </pre>
                </Stack>
            </Fade>
        </CardContent>
      </Card>
}
  
const ResumesView = ({userInfo}) => {
    const [uploadResume, {isLoading: isUploading }] = useUploadResumeMutation();
    console.log(userInfo);
    const resumeQuery = useGetPersonResumesQuery(userInfo.id);
  
    if(!resumeQuery.data || resumeQuery.isLoading) return <Loading />;
  
    const onUploadResume = (file, data) => {
      uploadResume({data: data, filename: file.name});
    }
  
    return <Card variant="outlined">
        <CardHeader title="Resumés" />
        <CardContent>
            <Stack direction="column" spacing={2}>
            {resumeQuery.data.map((resume) => <ResumeCard key={resume.id} resume={resume} />)}
            {resumeQuery.data.length == 0 && <Typography>No resumés uploaded.</Typography>}
            <Uploader title="Upload resumé" onCancel={() => {}} onUpload={onUploadResume} disabled={isUploading} />
            </Stack>
        </CardContent>

        </Card>;
}

export { ResumesView, ResumeCard }