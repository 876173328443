import {useListPersonsQuery, useCreatePersonMutation, useDeletePersonMutation, useUpdatePersonMutation} from "../../features/model/personSlice";
import { RESTList } from "../rest/List";
import { personFields } from "../../features/model/modelFields";
const PersonRESTView = ({extra = {}}) => {
    const personQuery = useListPersonsQuery({all: true});
    const addMutation = useCreatePersonMutation();
    const deleteMutation = useDeletePersonMutation();
    const updateMutation = useUpdatePersonMutation();
    return <RESTList
        query={personQuery}
        extra={extra}
        spec={personFields()}
        objectType={"person"}
        addMutation={addMutation}
        deleteMutation={deleteMutation}
        updateMutation={updateMutation}
    />;
}
export { PersonRESTView };