
import { apiSlice } from '../api/apiSlice'

export const uploadApi = apiSlice.injectEndpoints ({ 
    endpoints: (builder) => ({
        uploadResume: builder.mutation ({
            query: ({data, filename}) => ({
                url: `v2/create_resume/${filename}`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Resume"]
        }),
        uploadJob: builder.mutation ({
            query: ({data, filename}) => ({
                url: `v2/create_job/${filename}`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Job"]
        }),
        uploadQuestionList: builder.mutation ({
            query: ({questionlist_id, data, filename}) => ({
                url: `v2/questionlist/${questionlist_id}/upload/${filename}`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Document", "QuestionList"]
        }),
        uploadTest: builder.mutation ({
            query: ({kind, data, filename}) => ({
                url: `v2/test_data_upload/${kind}/${filename}`,
                method: 'POST',
                body: data
            })
        })
    }),
})

export const {
    useUploadResumeMutation,
    useUploadJobMutation,
    useUploadQuestionListMutation,
    useUploadTestMutation
} = uploadApi;