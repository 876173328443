import { Button, Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import Uploader from "../../components/Uploader";
import { useUploadTestMutation } from "../../features/uploadSlice";
import { useState } from "react";
import { JSONTree } from 'react-json-tree';

const TestView = () => {
    const [uploadTestDocument, {isLoading, data}] = useUploadTestMutation();
    const [showData, setShowData] = useState(null);
    
    const onUploadResume = (file, data) => {
        uploadTestDocument({kind: "resume", data: data, filename: file.name});
    }
  
    const onUploadJD = (file, data) => {
        uploadTestDocument({kind: "job", data: data, filename: file.name});
    }

    let output = null;
    if(data) {
        if(showData !== data) {
            setShowData(data);
        }
    }
    if(showData) {
        output = <Card variant="outlined">
            <CardHeader title="Output" />
            <CardContent>
            <JSONTree data={showData} />
            </CardContent>
        </Card>;
    }

    return <Stack direction="column" sx={{mt: 4, alignSelf: "center"}} spacing={4}>
        <Card variant="outlined">
            <CardHeader title="Test resume upload" />
            <CardContent>
                <Uploader onUpload={onUploadResume} />
            </CardContent>
        </Card>
        <Card variant="outlined">
            <CardHeader title="Test JD upload" />
            <CardContent>
                <Uploader onUpload={onUploadJD} />
            </CardContent>
        </Card>
        {output}
    </Stack>;
}

export { TestView };