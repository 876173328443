import { useUploadJobMutation } from "../features/uploadSlice";
import { useGetPersonJobsQuery } from "../features/model/personSlice";
import { useDeleteJobMutation, useUpdateJobMutation } from "../features/model/jobSlice";
import { useState } from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Fade, IconButton, Paper, Stack, Typography } from "@mui/material";
import { formatUTCDate } from "../environment/dates";
import Loading from "../components/Loading";
import Uploader from "../components/Uploader";
import Viewer from "../components/Viewer";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";

function JobCard({job}) {
    const [more, setMore] = useState(false);
    const [deleteJob] = useDeleteJobMutation();
    const [updateJob] = useUpdateJobMutation();
    const [name, setName] = useState(job.title ? job.title : job.documents[0]?.filename);
    const navigate = useNavigate();
    
    let uploaded = job.documents[0]?.created;
  
    return <Card variant="outlined">
        <CardHeader title={job.title} action={<IconButton onClick={()=>{setMore(!more);}}><MoreVertIcon /></IconButton>} />
        <CardActions>
            <Button startIcon={<PreviewIcon />} variant="outlined" onClick={() => {
                Viewer(job.documents[0], true);
            }}>View</Button>
            <Fade mountOnEnter unmountOnExit in={more}>
                <Button color="secondary" startIcon={<DeleteIcon />} variant="outlined" onClick={() => {
                    if (window.confirm(`Are you sure you want to delete the job description ${job.title}?`)) {
                        deleteJob(job.id);
                        navigate(0);
                    }
                }}>Delete</Button>
            </Fade>
        </CardActions>
        <CardContent>
        <Fade mountOnEnter unmountOnExit in={more}>
            <Typography>Uploaded: {formatUTCDate(uploaded)}</Typography>
        </Fade>
        </CardContent>
      </Card>
}
  
const JobsView = ({userInfo}) => {
    const [uploadJob, {isLoading: isUploading }] = useUploadJobMutation();
    const jobQuery = useGetPersonJobsQuery(userInfo.id);
  
    if(!jobQuery.data || jobQuery.isLoading) return <Loading />;
  
    const onUploadJob = (file, data) => {
      uploadJob({data: data, filename: file.name});
    }
  
    return <Card variant="outlined">
        <CardHeader title="Job Descriptions" />
        <CardContent>
            <Stack direction="column" spacing={2}>
            {jobQuery.data.map((job) => <JobCard key={job.id} job={job} />)}
            {jobQuery.data.length == 0 && <Typography>No job descriptions uploaded.</Typography>}
            <Uploader title="Upload JD" onCancel={() => {}} onUpload={onUploadJob} disabled={isUploading} />
            </Stack>
        </CardContent>
    </Card>;
}

export { JobsView, JobCard }